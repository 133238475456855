import DynamicComponent from "components/DynamicComponent"
import Meta from "components/Meta"
import { getPage } from "storyblok/api"
import Script from "next/script"

export default function Home({ story, meta }) {
  return (
    <>
      <Meta info={meta} />
      <DynamicComponent blok={story?.content} />
      <Script type="application/ld+json" id="event-schema">
        {`{
          "@context" : "https://schema.org",
          "@type" : "WebSite",
          "name" : "CHNK Behavior Health",
          "alternateName" : "CHNK",
          "url" : "https://www.chnk.org/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://www.chnk.org/search?q={search_term_string}"
            },
            "query-input": "required name=search_term_string"
          }
        }`}
      </Script>
    </>
  )
}

export async function getStaticProps({ preview = null }) {
  // home is the default slug for the homepage in Storyblok, but we've aliased it to '/' here and in the visual editor.
  // This template would otherwise be identical to pages/[...slug.js] and therefore unnecessary
  const { page, globals } = await getPage("home", preview)

  return {
    props: {
      globals,
      story: page ?? null,
      meta: page?.content?.twentysixty_seo ?? null,
      preview,
    },
  }
}
